export function QwikLogo() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 256 272"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="xMidYMid"
    >
      <title>Qwik</title>
      <g>
        <path
          d="M224.802633,271.548066 L176.04373,223.065139 L175.29865,223.17158 L175.29865,222.639928 L71.6062201,120.251664 L97.1567871,95.5849889 L82.1459867,9.46423235 L10.923994,97.7115967 C-1.21249511,109.938494 -3.44814914,129.82085 5.28159772,144.493127 L49.7819901,218.280821 C56.5954198,229.657294 68.9446684,236.461781 82.2524269,236.355341 L104.289921,236.14246 L224.802633,271.548066 Z"
          fill="#18B6F6"
        ></path>
        <path
          d="M251.41376,96.0096521 L241.619075,77.935132 L236.508852,68.685266 L234.486489,65.0701426 L234.273609,65.2830228 L207.445212,18.8201544 C200.738387,7.12474433 188.176258,-0.105173442 174.549179,4.00642427e-13 L151.021523,0.639029547 L80.8637673,0.851690324 C67.5565576,0.958020713 55.4201892,7.97527771 48.7129802,19.3517514 L6.02242644,103.983883 L82.3544777,9.25113264 L182.427394,119.187811 L164.648054,137.15644 L175.29426,223.171031 L175.4007,222.958151 L175.4007,223.171031 L175.18782,223.171031 L175.4007,223.383362 L183.704676,231.464033 L224.053163,270.909425 C225.756753,272.504381 228.524745,270.590653 227.353355,268.570486 L202.441978,219.555907 L245.877777,139.283048 L247.261498,137.688092 C247.793699,137.05 248.3259,136.412457 248.752209,135.774364 C257.269064,124.18501 258.439906,108.66231 251.41376,96.0096521 Z"
          fill="#AC7EF4"
        ></path>
        <polygon
          fill="#FFFFFF"
          points="182.745617 118.763148 82.3533804 9.35773736 96.6190999 95.053337 71.0685329 119.826452 175.080283 223.065139 165.711906 137.36932"
        ></polygon>
      </g>
    </svg>
  );
}
