export function NuxtLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      id="nuxt-dot-js"
      width="64"
      height="64"
    >
      <path d="m19.62 18.94-5.31-9.43 5.44 9.7c-.04-.09-.08-.18-.13-.27z"></path>
      <path
        fill="#3B8070"
        d="M23.85 20.36c-.35.61-1.12.64-1.27.64h-3.44c.72 0 1.17-.78.82-1.41l-.01-.01h2.55l-6.94-12.3-1.25 2.23-.81-1.43.91-1.61c.36-.65.82-.79 1.14-.79.7 0 1.11.66 1.18.79l7.02 12.49c.37.65.26 1.12.1 1.4z"
      ></path>
      <path
        fill="#41B883"
        d="m13.5 8.08-.81 1.43L9.93 4.6 1.5 19.58H7l-.01.01c-.36.63.1 1.41.82 1.41H1.4c-.75 0-1.09-.33-1.25-.61-.35-.61.01-1.3.08-1.43L8.77 3.79c.37-.65.82-.79 1.14-.79.7 0 1.11.66 1.18.78l2.41 4.3z"
      ></path>
      <path d="m19.615 18.784.001.002z"></path>
      <ellipse cx="19.853" cy="19.408" rx=".007" ry=".001"></ellipse>
      <path d="m19.624 18.8-.008-.014.003.006zM19.76 19.24c.04.11.08.22.1.34h.09l-.19-.34zm-12.59.34v-.02c.04-.22.11-.42.21-.6l5.18-9.21L7 19.58h.17z"></path>
      <path
        fill="#35495E"
        d="m19.96 19.59-.01-.01h-.09c-.02-.12-.06-.23-.1-.34l-.01-.03-5.44-9.7-.81-1.43-.81 1.43-.13.24-5.18 9.21c-.1.18-.17.38-.21.6v.02H7l-.01.01c-.36.63.1 1.41.82 1.41h11.33c.72 0 1.17-.78.82-1.41zm-11.32-.01 4.86-8.64 4.86 8.64H8.64z"
      ></path>
    </svg>
  );
}
