export function MeteorLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128 128"
      id="meteor"
      width="64"
      height="64"
    >
      <path
        fill="#DF5052"
        d="M120.3 65.4c1.2 0 2.1-.9 2.1-2.1 0-.5-.2-1-.5-1.3-.1-.2-28-25.8-28-25.8l24.6 28.1c.3.7 1 1.1 1.8 1.1zM126 79.7l-.2-.2-60.8-55.5 55.7 60.6.2.2c.6.6 1.5 1 2.5 1 2 0 3.6-1.6 3.6-3.6 0-.9-.4-1.8-1-2.5zM99.1 117.3l-88-81.5 81.1 88.5c.9 1 2.2 1.7 3.7 1.7 2.7 0 4.9-2.2 4.9-4.9 0-1.5-.6-2.9-1.7-3.8zM1 2l107.6 114.6.1.1c.9.9 2.1 1.4 3.4 1.4 2.7 0 4.9-2.2 4.9-4.9 0-1-.3-2-.9-2.8-.3-.5-115.1-108.4-115.1-108.4zM123.4 94.8l-88-81.5 81.1 88.5c.9 1 2.2 1.7 3.7 1.7 2.7 0 4.9-2.2 4.9-4.9 0-1.6-.7-2.9-1.7-3.8zM80.9 119.6l-.2-.2-60.8-55.5 55.7 60.6.2.2c.6.6 1.5 1 2.5 1 2 0 3.6-1.6 3.6-3.6 0-1-.4-1.8-1-2.5zM34.1 91.7l24.6 28.1c.4.6 1 1.1 1.8 1.1 1.2 0 2.1-.9 2.1-2.1 0-.5-.2-1-.5-1.3-.2-.1-28-25.8-28-25.8z"
      ></path>
    </svg>
  );
}
