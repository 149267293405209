export function LaravelLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      fill="none"
      viewBox="0 0 40 40"
      id="laravel"
    >
      <path
        fill="#FF2D20"
        fill-rule="evenodd"
        d="M10.5528 4.10557C10.8343 3.96481 11.1657 3.96481 11.4472 4.10557L17.4472 7.10557C17.786 7.27496 18 7.62123 18 8L18 20.759L22 18.4256V12C22 11.6212 22.214 11.275 22.5528 11.1056L28.5528 8.10557C28.8343 7.96481 29.1657 7.96481 29.4472 8.10557L35.4472 11.1056C35.786 11.275 36 11.6212 36 12L36 19C36 19.3632 35.8031 19.6978 35.4856 19.8742L30 22.9217L30 28.2903C30 28.6525 29.8042 28.9864 29.488 29.1631L17.488 35.8728C17.1848 36.0424 16.8152 36.0424 16.512 35.8728L4.51197 29.1631C4.19584 28.9864 4 28.6525 4 28.2903L3.99998 8C3.99998 7.62123 4.21399 7.27496 4.55277 7.10557L10.5528 4.10557ZM30 20.6338L34 18.4116L34 13.618L30 15.618L30 20.6338ZM29 13.882L32.7639 12L29 10.118L25.2361 12L29 13.882ZM24 13.618V18.4116L28 20.6338L28 15.618L24 13.618ZM26.9409 22.3333L23.0121 20.1507L17.5186 23.3552C17.5084 23.3614 17.498 23.3675 17.4875 23.3733L13.1007 25.9323L16.9754 27.8697L26.9409 22.3333ZM16 29.618L10.5528 26.8944C10.214 26.725 10 26.3788 10 26L9.99999 11.618L5.99999 9.61803L6 27.7037L16 33.2952V29.618ZM18 33.2952V29.5884L28 24.0328L28 27.7037L18 33.2952ZM7.23606 8L11 9.88197L14.7639 8L11 6.11803L7.23606 8ZM16 9.61803L12 11.618L12 24.259L16 21.9256L16 9.61803Z"
        clip-rule="evenodd"
      ></path>
    </svg>
  );
}
