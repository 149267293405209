export function DjangoLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 24 24"
      viewBox="0 0 24 24"
      id="django"
      width="64"
      height="64"
    >
      <path
        fill="#0D2E1E"
        d="M21.25,6.06L21.25,6.06l-3.897-0.001v7.747c0,3.414-0.177,4.714-0.733,5.835c-0.531,1.096-1.366,1.811-3.088,2.626L17.15,24c1.721-0.866,2.557-1.656,3.188-2.905c0.684-1.299,0.911-2.803,0.911-5.937V6.06L21.25,6.06z"
      ></path>
      <path
        fill="#0D2E1E"
        d="M15.049,18.165V0h-3.896v5.984C10.571,5.831,10.089,5.78,9.457,5.78c-4.076,0-6.707,2.599-6.707,6.6c0,4.154,2.479,6.32,7.238,6.32C11.582,18.701,13.05,18.548,15.049,18.165L15.049,18.165z M6.724,12.304c0-2.141,1.138-3.364,3.113-3.364H9.832l0.082-0.001c0.443,0,0.868,0.075,1.238,0.205v6.294c-0.557,0.077-0.911,0.102-1.341,0.102C7.811,15.539,6.724,14.394,6.724,12.304L6.724,12.304z"
      ></path>
      <path
        fill="#0B281A"
        d="M21.25 12h-3.897v1.807c0 3.414-.177 4.714-.733 5.835-.531 1.096-1.366 1.811-3.088 2.626L17.15 24c1.721-.866 2.557-1.656 3.188-2.905.684-1.299.911-2.803.911-5.937V12L21.25 12zM15.049 12h-3.896v3.438c-.557.077-.911.102-1.341.102-2 0-3.088-1.146-3.088-3.235 0-.104.003-.205.008-.304H2.758c-.005.125-.008.252-.008.38 0 4.154 2.479 6.32 7.238 6.32 1.594 0 3.062-.153 5.061-.535V12L15.049 12z"
      ></path>
      <polygon
        fill="#0D2E1E"
        points="21.25 .021 17.353 .021 17.353 4.048 21.25 4.048 21.25 .021"
      ></polygon>
      <polygon
        fill="#0B281A"
        points="19.302 .021 17.353 .021 17.353 4.048 19.302 4.048 19.302 .021"
      ></polygon>
    </svg>
  );
}
